import React, { useState, useEffect } from 'react';
import Layout from '@accrosoft-ltd/vf-careers-site-theme/src/components/Layout';

import SmartFeedList from '@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedList';
import SmartFeedJobAlerts from '@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedJobAlerts';

import { useFiltersContext } from '@accrosoft-ltd/vf-careers-site-theme/src/context/FiltersContext';
import { useI18nContext } from '@accrosoft-ltd/vf-careers-site-theme/src/i18n/i18n';
import Card from 'react-bootstrap/Card';

require('es6-promise').polyfill();

export default function VacanciesPage(props) {
  const {
    i18nState: { lang },
    t,
  } = useI18nContext();
  const {
    filtersState: { filters },
    onSelectionChange,
  } = useFiltersContext();

  let internalView = false;
  let iFrameView = false;
  let HideCareersLink = false;

  if (props.urlLocation && props.urlLocation.search) {
    let searchParams = new URLSearchParams(props.urlLocation.search);
    let entries = searchParams.entries();

    for (let pair of entries) {
      if (pair[0] === 'iframe') {
        iFrameView = pair[1];
      }
      if (pair[0] === 'HideCareersLink') {
        HideCareersLink = pair[1];
      }
      if (pair[0] === 'IsIntranet') {
        internalView = pair[1];
      }
    }
  }

  const [vacancyLoading, setVacancyLoading] = useState(true);

  const siteConfig = props.siteConfig;

  const companyName =
    siteConfig?.CompanyName ||
    props?.smartfeedCompanyData?.CompanyName ||
    'NPTC Group of Colleges';

  const onNavChange = (value) => {
    var temp = {
      target: {
        name: 'postingtype',
        value,
      },
    };
    onSelectionChange(temp);
  };

  return (
    <Layout
      siteConfig={siteConfig}
      location={props.location}
      apiKey={props.apiKey}
      groupOrIdParam={props.groupOrIdParam}
      smartfeedCompanyData={props.smartfeedCompanyData}
      appGatewayURL={props.appGatewayURL}
      vacanciesRouteLoading={props.vacanciesRouteLoading}
      vacanciesRouteError={props.vacanciesRouteError}
      title={`${companyName} Careers`}
      description={`Careers at ${companyName}`}
      useGroupVacsLink={props.useGroupVacsLink}
    >
      {!props.vacanciesRouteLoading &&
      (siteConfig.siteConfig || props.smartfeedCompanyData) &&
      props.apiKey ? (
        <div className="container py-3">
          {/* hero image */}

          <div
            class="app-hero-background-image"
            style={{
              backgroundImage:
                'url(' +
                'https://cdn2.accropress.com/14c0e709-bdb8-40f9-9c7b-0cf9a425f0b4/media/global/c84163ef-2609-49b3-9539-1bf5190e1c8f_full_adobestock_254378731.jpeg' +
                ')',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          ></div>
          <div className="row"></div>
          {filters?.postingtype !== 'Alerts' ? (
            <>
              <div className="row">
                <div class="app-hero-wrapper app-hero-home app-hero-video">
                  <div class="app-hero-background-overlay"></div>
                  <div class="app-hero-content-center"></div>
                  <div class="app-hero-content-bottom"></div>
                </div>
                <div className="col-12 p-0">
                  <SmartFeedList
                    siteConfig={siteConfig}
                    apiKey={siteConfig.apiKey || props.apiKey}
                    group={
                      siteConfig.group
                        ? siteConfig.group
                        : props.groupOrIdParam === 'id'
                        ? false
                        : props.groupOrIdParam === 'group'
                        ? true
                        : false
                    }
                    smartfeedCompanyData={
                      siteConfig.smartfeedCompanyData ||
                      props.smartfeedCompanyData
                    }
                    useGroupVacsLink={props.useGroupVacsLink}
                    vacancyLoading={vacancyLoading}
                    setVacancyLoading={setVacancyLoading}
                  />
                </div>
              </div>
            </>
          ) : (
            <SmartFeedJobAlerts
              siteConfig={siteConfig}
              setVacancyLoading={false}
              apiKey={siteConfig.apiKey}
              group={siteConfig.group || false}
              gdprKey={siteConfig.gdprKey || siteConfig.apiKey}
              JobAlertCompanySettings={siteConfig.JobAlertCompanySettings}
              CompanyGroupName={siteConfig.companyName}
              OptInLink={
                siteConfig.companyCareersSite
                  ? `${siteConfig.careersSiteDomain}/job-alerts/optin/?OptInID=`
                  : siteConfig.OptInLink ||
                    `${siteConfig.careersSiteDomain}/job-alerts/${siteConfig.apiKey}/optin/?OptInID=`
              }
              UnsubscribeLink={
                siteConfig.companyCareersSite
                  ? `${siteConfig.careersSiteDomain}/job-alerts/unsubscribe/?UnsubscribeID=`
                  : siteConfig.UnsubscribeLink ||
                    `${siteConfig.careersSiteDomain}/job-alerts/${siteConfig.apiKey}/unsubscribe/?UnsubscribeID=`
              }
              VacancyPageBaseURL={
                siteConfig.companyCareersSite
                  ? `${siteConfig.careersSiteDomain}/vacancies/vacancy/`
                  : siteConfig.VacancyPageBaseURL ||
                    `${siteConfig.careersSiteDomain}/vacancies/${siteConfig.apiKey}/vacancy/`
              }
              ReturnToSearchURL={
                siteConfig.companyCareersSite
                  ? `${siteConfig.careersSiteDomain}/vacancies/`
                  : siteConfig.ReturnToSearchURL ||
                    `${siteConfig.careersSiteDomain}/vacancies/${siteConfig.apiKey}/`
              }
              location={siteConfig.showLocationJA}
              region={siteConfig.showRegionJA}
              category={siteConfig.showCategoryJA}
              industry={siteConfig.showIndustryJA}
              jobType={siteConfig.showJobTypeJA}
              jobTime={siteConfig.showJobTimeJA}
            />
          )}
        </div>
      ) : (
        <div className="notfound">
          <section className="app-content">
            <div className="app-content-content">
              <div className="container">
                <div className="loading-panel">
                  <div className="loading-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <span className="loading-text">
                    Please wait... If content is not shown soon then you may
                    have the wrong URL.
                  </span>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}

      {/* cards */}
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-12 col-md-4 mb-4">
            <div>
              <Card>
                <Card.Img
                  className="btn-card-external-img"
                  variant="top"
                  src="https://cdn1.accropress.com/NPTC/external/application.jpeg"
                />
                <Card.Body style={{ padding: '0px' }}>
                  <a
                    className="btn-card-external btn btn-primary"
                    href={
                      lang === 'en-GB'
                        ? 'https://www.nptcgroup.ac.uk/application-process/'
                        : 'https://www.nptcgroup.ac.uk/cy/y-broses-ymgeisio/'
                    }
                    variant="primary"
                  >
                    {t('custom-cards.1')}
                  </a>
                </Card.Body>
              </Card>
            </div>
          </div>
          <div className="col-xs-12 col-12 col-md-4 mb-4">
            <Card>
              <Card.Img
                className="btn-card-external-img"
                variant="top"
                src="https://cdn1.accropress.com/NPTC/external/faq.jpeg"
              />
              <Card.Body style={{ padding: '0px' }}>
                <a
                  className="btn-card-external btn btn-primary"
                  href={
                    lang === 'en-GB'
                      ? 'https://www.nptcgroup.ac.uk/faqs/'
                      : 'https://www.nptcgroup.ac.uk/cy/cwestiynau-cyffredin/'
                  }
                  variant="primary"
                >
                  {t('custom-cards.2')}
                </a>
              </Card.Body>
            </Card>
          </div>
          <div className="col-xs-12 col-12 col-md-4 mb-4">
            <Card>
              <Card.Img
                className="btn-card-external-img"
                variant="top"
                src="https://cdn1.accropress.com/NPTC/external/recruitment.jpeg"
              />
              <Card.Body style={{ padding: '0px' }}>
                <a
                  className="btn-card-external-separate btn btn-primary"
                  style={{ whiteSpace: 'pre-wrap' }}
                  href={
                    lang === 'en-GB'
                      ? 'https://www.nptcgroup.ac.uk/recruitment-ex-offenders-policy/'
                      : 'https://www.nptcgroup.ac.uk/cy/polisi-recriwtio-cyn-droseddwyr/'
                  }
                  variant="primary"
                >
                  {t('custom-cards.3')}
                </a>
              </Card.Body>
            </Card>
          </div>
          <div className="col-xs-12 col-12 col-md-4 mb-4">
            <Card>
              <Card.Img
                className="btn-card-external-img"
                variant="top"
                src="https://cdn1.accropress.com/NPTC/external/selection.jpeg"
              />
              <Card.Body style={{ padding: '0px' }}>
                <a
                  className="btn-card-external btn btn-primary"
                  href={
                    lang === 'en-GB'
                      ? 'https://www.nptcgroup.ac.uk/interview-process/'
                      : 'https://www.nptcgroup.ac.uk/cy/y-broses-ddethol/'
                  }
                  variant="primary"
                >
                  {t('custom-cards.4')}
                </a>
              </Card.Body>
            </Card>
          </div>
          <div className="col-xs-12 col-12 col-md-4 mb-4">
            <Card>
              <Card.Img
                className="btn-card-external-img"
                variant="top"
                src="https://cdn1.accropress.com/NPTC/external/staff.jpeg"
              />
              <Card.Body style={{ padding: '0px' }}>
                <a
                  className="btn-card-external btn btn-primary"
                  href={
                    lang === 'en-GB'
                      ? 'https://www.nptcgroup.ac.uk/staff-benefits/'
                      : 'https://www.nptcgroup.ac.uk/cy/buddion-staff/'
                  }
                  variant="primary"
                >
                  {t('custom-cards.5')}
                </a>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>

      <div className="_logos_1kqab4 _logos_1kqab4_footer_2">
        <div className="container">
          <div className="row">
            <div className="footer-logo-container col-xs-12 col-12 col-md-3 col-3-m">
              <div className="footer-logo footer-logo-apprenticeships">
                <img src={t('custom-logos.1')} />
              </div>
            </div>
            <div className="footer-logo-container col-xs-12 col-12 col-md-2 col-2-m">
              <div className="footer-logo footer-logo-mindful-employer">
                {' '}
                <img src={t('custom-logos.2')} />
              </div>
            </div>
            <div className="footer-logo-container col-xs-12 col-12 col-md-3 col-3-m">
              <div className="footer-logo footer-logo-disability-employer">
                {' '}
                <img src={t('custom-logos.3')} />
              </div>
            </div>
            <div className="footer-logo-container col-xs-12 col-12 col-md-2 col-2-m">
              <div className="footer-logo footer-logo-mindful-employer">
                {' '}
                <img
                  style={{
                    maxHeight: '100%',
                    width: 'auto',
                  }}
                  src={t('custom-logos.5')}
                />
              </div>
            </div>
            <div className="footer-logo-container col-xs-12 col-12 col-md-2 col-2-m">
              <div className="footer-logo footer-logo-armed-forces">
                {' '}
                <img src={t('custom-logos.4')} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
